import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAdministrator } from '../common/util/permissions';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const RaceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const admin = useAdministrator();

  const item = data[index];

  return (
    <div style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => navigate(`/race/${item.id}`)}
        disabled={!admin && item.disabled}
      >
        <ListItemAvatar>
          <Avatar>
            <img
              className={classes.icon}
              src={mapIcons[mapIconKey(item.category)]}
              alt=""
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
    </div>
  );
};

export default RaceRow;
