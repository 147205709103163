import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default (keyword, filter, filterSort, filterMap, positions, setFilteredGroups) => {
  const groups = useSelector((state) => state.groups.items);

  useEffect(() => {
    const filtered = Object.values(groups)
      .filter((geofence) => {
        const lowerCaseKeyword = keyword.toLowerCase();
        return [geofence.name].some((s) => s && s.toLowerCase().includes(lowerCaseKeyword));
      });
    // switch (filterSort) {
    //   case 'name':
    //     filtered.sort((device1, device2) => device1.name.localeCompare(device2.name));
    //     break;
    //   case 'lastUpdate':
    //     filtered.sort((device1, device2) => {
    //       const time1 = device1.lastUpdate ? moment(device1.lastUpdate).valueOf() : 0;
    //       const time2 = device2.lastUpdate ? moment(device2.lastUpdate).valueOf() : 0;
    //       return time2 - time1;
    //     });
    //     break;
    //   default:
    //     break;
    // }
    setFilteredGroups(filtered);
  }, [keyword, filter, filterSort, filterMap, groups, positions, setFilteredGroups]);
};
