import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BottomMenu from '../common/components/BottomMenu';
// https://traccar.isaac.clp.nz/api/reports/events?deviceId=2&from=2023-02-28T11:00:00.000Z&to=2023-03-31T10:59:59.999Z&type=geofenceEnter

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      height: `calc(100% - ${theme.spacing(3)})`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(1.5),
      zIndex: 3,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
    },
  },
  race: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: theme.dimensions.drawerWidthDesktop,
      top: 0,
      height: `calc(100% - ${theme.spacing(3)})`,
      right: 0,
      margin: theme.spacing(1.5),
      zIndex: 3,
      padding: theme.spacing(1),
    },
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
  },
}));

const RacePage = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { id } = useParams();

  const geofence = useSelector((state) => state.groups.items[id]);

  const events = useSelector((state) => state.events.items);

  console.log({ events });

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <Paper square elevation={3} className={classes.header} />
        <div className={classes.middle}>
          <Paper
            square
            className={classes.contentList}
          >
            {geofence?.name}
          </Paper>
        </div>
        {desktop && (
          <div className={classes.footer}>
            <BottomMenu />
          </div>
        )}
      </div>
      <div className={classes.race}>
        {geofence?.name}
      </div>
    </div>
  );
};

export default RacePage;
